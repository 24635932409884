import { ResourceUrlEnum } from '@/resource/enum';
import { ProductionOrderProductResource } from '@/resource/model';
import { handleDownload, messageError } from '@/utils';
import { axiosRequest } from '@/utils/axios-request';
import { getBatchOperationErrorObjs, getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { AxiosResponse } from 'axios';
import { ApiError, ApiResponse, axiosService } from '../axios';
import { Paging, Query, SortOptions } from '../base';

class ReceiptService {
  /**
   * 更新单据输出生产进度
   * @param data 请求参数
   * @returns 
   */
  public updatePrintingProgress(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<Object>(`${ResourceUrlEnum.printingReceipt}/updateProgress`, data)
        .then((res: ApiResponse<Object>) => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as Array<any>));
            }

            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 批量接单
   * @param idList 单据ID集合
   * @param deviceId 设备ID
   * @returns
   */
  public batchTake(idList: Array<number>, deviceId?: number | null): Promise<any> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<Object>(`${ResourceUrlEnum.printingReceipt}/batchTake`, { idList, deviceId })
        .then((res: ApiResponse<Object>) => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as Array<any>));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量完成单据
   * @param idList 单据ID集合
   */
  public batchFinish(idList: Array<number>): Promise<any> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<Object>(`${ResourceUrlEnum.printingReceipt}/batchFinish`, { idList })
        .then((res: ApiResponse<Object>) => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as Array<any>));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 查询超时/即将超时单据数量
   */
  public getTimeoutCount(): Promise<any> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Object>(`${ResourceUrlEnum.printingReceipt}/overtime`)
        .then((res: ApiResponse<Object>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 数据导出
   */
  public export(query: Query<any>): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .post<any>(`${ResourceUrlEnum.printingReceipt}/export`, query, { responseType: 'blob' })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getById(id: number): Promise<ProductionOrderProductResource> {
    console.log(id);
    throw new Error('Method not implemented.');
  }

  /**
   * 分页查询单据
   * @param query 查询参数
   * @param paging 分页参数
   * @returns
   */
  public getList(
    query: Query<ProductionOrderProductResource>,
    paging: Paging,
    sortOptions: SortOptions<ProductionOrderProductResource>
  ): Promise<ApiResponse<Array<ProductionOrderProductResource>>> {
    const cloneQuery = {};
    if (Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    Object.assign(cloneQuery, paging);
    Object.assign(cloneQuery, sortOptions);

    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<ProductionOrderProductResource>>(
          `${ResourceUrlEnum.printingReceipt}/listPage`,
          cloneQuery
        )
        .then((res: ApiResponse<Array<ProductionOrderProductResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  public post(resource: ProductionOrderProductResource): Promise<void> {
    console.log(resource);
    throw new Error('Method not implemented.');
  }
  public put(resource: ProductionOrderProductResource): Promise<void> {
    console.log(resource);
    throw new Error('Method not implemented.');
  }
  public delete(id: number): Promise<void> {
    console.log(id);
    throw new Error('Method not implemented.');
  }
}
const printingReceiptService = new ReceiptService();
export default printingReceiptService;
